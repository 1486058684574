import React from "react";

import CarouselWidget from "../assets/generalCarousel";

import image3a from '../Images/excav3.jpg';
import image3b from '../Images/Excavator.jpg';
import image3c from '../Images/digger2.jpg';
import image3d from '../Images/Digging.jpg';
import image3e from '../Images/dig1.jpg';

import image4a from '../Images/bigdrill2.jpeg';
import image4b from '../Images/bigdrill3.jpg';
import image4c from '../Images/DRILL.jpeg';
import image4d from '../Images/DirDrill.jpeg';
import image4e from '../Images/boring.jpg';

//import image5a from '../Images/vactruck.jpg';
import image5b from '../Images/Vac.jpg';
//import image5c from '../Images/Vac02.jpg';

import image60 from '../Images/Caro-01.png';
import image6a from '../Images/concrete3.jpg';
import image6b from '../Images/Concrete2.png';
import image6c from '../Images/concrete2.jpg';
import image6d from '../Images/Concrete.jpg';

function Services1() {
    const imageGroup = [
        [image3a,image3b,image3c, image3d,image3e],
        [image4a,image4b,image4c,image4d,image4e],
        [image5b],
        [image60,image6a, image6b,image6c, image6d]   
    ];
  return (
<div>
    <div className="about">
        <h2>Civil Construction Services</h2>
            <p>We are proud of our civil crews who are passionate about their roles and always eager to learn new skills. They are the best operators you could ever ask for, delivering high-quality results for all job types. Our fleet of civil equipment is second to none, with a range of machines and vehicles to suit any project.</p>
            <p>We are constantly investing in new equipment to keep our fleet up to date and maintain our edge in the civil excavation industry.</p>
            <p>We have:
                <li>2 * Directional Drills for rock drilling and installing pipes of any diameter</li>
                <li>A Hydro excavation truck with vac facility</li>
                <li>2 * Smaller hydro vac trailers for backup</li>
                <li>Multiple support tippers and trucks for transporting materials and waste</li></p>
    </div>
    <div className="grid">
        <CarouselWidget images={imageGroup[0]}/>
            
        <div className="aboutService">
            <h2>Civil Excavation</h2>
            <p>In the rapidly evolving world of telecommunications, the role of civil excavation is pivotal. It’s the unseen hero that lays the groundwork for our connected society. At the heart of this process are our Wacker Neuson Excavators, the workhorses that make trenching for cables and pipes possible.</p>
            <p>Our approach to open trenching is meticulous, ensuring that every cable and pipe is laid with precision. We don’t just dig; we create pathways for the future. The Wacker Neuson Excavators, with their precision engineering, are instrumental in achieving the perfect balance of depth, width, and alignment, guaranteeing the integrity of the telecommunications network</p>
            <p>The agility of Wacker Neuson Excavators is unmatched. Equipped with a variety of attachments, they’re not just digging machines, they’re multi-functional tools that adapt to any challenge. Whether it’s removing debris, leveling ground, or compacting soil, these excavators redefine efficiency.</p>
            <p>Before a single telecommunication tower rises, our excavators set the stage. They clear the land of obstructions and ensure a solid foundation for the installation of towers, poles, antennas, and cabinets. It’s the first step towards a more connected future.</p>
            <p>Once our work is complete, we take great care to restore the land. It’s not just about laying cables; it’s about preserving the environment. Our Excavators allow us to fill trenches and reinstate surfaces with minimal impact, leaving behind nothing but the promise of connectivity.</p>
        </div>

        <div className="aboutService">
            <h2>Directional Drilling</h2>
            <p>In the telecommunications sector, the installation of underground cables and pipes is a critical task. Traditional trenching methods are not only time-consuming but also disruptive to the environment and urban landscapes. This is where horizontal directional drilling (HDD) comes into play, offering a trenchless solution that is both efficient and minimally invasive.</p>
            <p>HDD technology allows for the precise placement of utilities beneath various obstacles, including roads, waterways, and constructed areas. By utilizing advanced drill heads and steering mechanisms, we can navigate the subsurface with remarkable accuracy. This method is complemented by real-time monitoring systems, to track the drill’s trajectory, ensuring optimal placement and minimal disturbance.</p>
            <p>Enter Grundodrills, the compact yet robust machines that have transformed the HDD process. Their sophisticated steering and tracking systems grant operators unparalleled control over the drilling process, further enhancing the precision of HDD.</p>
            <p>Adopting Grundodrills in HDD operations delivers threefold advantages:
                <li>Economic Efficiency: By circumventing extensive excavation and surface restoration, Grundodrills significantly cut down on project timelines and costs.</li>
                <li>Environmental Stewardship: The trenchless nature of HDD with Grundodrills preserves the integrity of ecosystems and urban infrastructures, mitigating the environmental footprint.</li>
                <li>Quality Assurance: The gentle installation process safeguards the structural integrity of cables and pipes, ensuring reliable performance post-installation.</li>
            </p>

        </div>

        <CarouselWidget images={imageGroup[1]}/>
            
        <CarouselWidget images={imageGroup[2]}/>

        <div className="aboutService">
            <h2>Hydro Non-destructive Excavation</h2>
            <p>Hydro Non-destructive Excavation emerges as a innovative technique that employs pressurized water to gently dislodge soil, coupled with vacuum suction to remove debris. Unlike traditional excavation methods, this approach minimizes the risk of collateral damage to underground utilities, which is crucial when dealing with sensitive communication lines.</p>
            <p>For the telecommunications sector, the adoption of such techniques is not just a matter of efficiency. It’s a strategic investment in the longevity and reliability of our communication networks. You can clearly see its key advantages:
                <li>Enhanced Safety: We ensure the safety of both workers and existing infrastructure by significantly lowering the likelihood of accidents.</li>
                <li>Increased Precision: The targeted nature of the water stream allows for meticulous site work, preserving the integrity of the surrounding area.</li>
                <li>Environmental Consideration: This method is less invasive, protecting the natural habitat and reducing the ecological footprint of excavation activities.</li>
                <li>Operational Visibility: Clearing the site with this provides an unobstructed view, facilitating accurate assessments and efficient progress.</li>
            </p>
        </div>  

        <div className="aboutService">
            <h2>Concrete Reinstatement</h2>
            <p>Concrete reinstatement is not just about repairing the physical damage, it’s about restoring the community’s trust in the resilience of their infrastructure. For the telecommunication industry, this translates to uninterrupted service delivery and the fortification of essential assets.</p>
            <p>This attention to detail is particularly beneficial for the community, where the robustness of ground-level infrastructure directly impacts the reliability of service.</p>
        </div>

        <CarouselWidget images={imageGroup[3]}/>
        
    </div>
</div>
  );
}

export default Services1;
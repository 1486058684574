import React from "react";
import './App.css';
import './colour.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './assets/scrollToTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Home,
  Contact,
  About,
  Navigation,
  Footer,
  Services,
  Services1,
  Services2,
  Services3,
  Services4,
  Services5,
  Services6,
  Lequinn
} from "./pages";

function App() {
  return (
    <>
      <Router>
        <Navigation />
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/services" element={<Services />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/contact" element={<Contact />}></Route>
            {/* <Route exact path="/lequinn" element={<Lequinn />}></Route> */}

            <Route exact path="/construction" element={<Services1 />}></Route>
            <Route exact path="/locating" element={<Services2 />}></Route>
            <Route exact path="/telecommunication" element={<Services4 />}></Route>
            <Route exact path="/developments" element={<Services5 />}></Route>
            <Route exact path="/cabling" element={<Services6 />}></Route>
            <Route exact path="/resources" element={<Services6 />}></Route>
          </Routes>
        </ScrollToTop>
        <Footer/>
      </Router>
      <ToastContainer position="top-center" />
    </>
  );
}

export default App;

import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { NavLink } from "react-router-dom";

//import image1 from '../Images/Caro-01.png';
import image1 from '../Images/bigdrill2.jpeg'
import image2 from '../Images/Caro02.jpg';
import image3 from '../Images/Caro03.jpg';
import image4 from '../Images/newsite.jpg';
import image5 from '../Images/site.png';
import image6 from '../Images/Internal.jpg';

import icon1 from '../Images/FB.png';
import icon2 from '../Images/Insta.png';
import icon3 from '../Images/LinkedIn.png';

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  
  return (
    <div>
      <Carousel className = "footer" activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <NavLink to="./construction">
          <img className = "sliderImg" src={image1} alt="test" text="First slide"/>
            <Carousel.Caption>
              <h3>Civil Construction</h3>
            </Carousel.Caption>
            </NavLink>
        </Carousel.Item>
        <Carousel.Item>
          <NavLink to="./locating">
          <img className = "sliderImg" src={image2} alt="test" text="Second slide" />
            <Carousel.Caption>
              <h3>Underground Service Locations</h3>
            </Carousel.Caption>
          </NavLink>
        </Carousel.Item>
        <Carousel.Item>
          <NavLink to="./telecommunication">
          <img className = "sliderImg" src={image3} alt="test" text="Third slide" />
            <Carousel.Caption>
              <h3>Telecommunications</h3>
            </Carousel.Caption>
          </NavLink>
        </Carousel.Item>
        <Carousel.Item>
          <NavLink to="./developments">
          <img className = "sliderImg" src={image4} alt="test" text="Fourth slide" />
            <Carousel.Caption>
              <h3>New Developments and Sub-Divisions</h3>
            </Carousel.Caption>
          </NavLink>
        </Carousel.Item>
        <Carousel.Item>
          <NavLink to="./cabling">
          <img className = "sliderImg" src={image6} alt="test" text="Fifth slide" />
            <Carousel.Caption>
              <h3>Cabling Private Networks</h3>
            </Carousel.Caption>
          </NavLink>
        </Carousel.Item>
      </Carousel>
      <div className="footer-basic">
        <footer>
            <div className="social">
              <a href="https://www.facebook.com/AdtechContracting/"> <img className="socialicons" src={icon1} alt="FB"/></a>
              <a href="#"> <img className="socialicons" src={icon2} alt="Insta"/></a>
              <a href="#"> <img className="socialicons" src={icon3} alt="LinkedIn"/></a>
            </div>
            <ul className="list-inline">
              <li className="list-inline-item"><NavLink to="./">Home</NavLink></li>
              <li className="list-inline-item"><NavLink to="./about">About</NavLink></li>
              <li className="list-inline-item"><NavLink to="./contact">Contact</NavLink></li>
              {/* <li className="list-inline-item"><NavLink to="./lequinn">LeQuinn</NavLink></li> */}
            </ul>
            <p className="copyright">Adtech Contracting © 2018</p>
        </footer>
      </div>
  </div>
  );
}



export default ControlledCarousel;
import React from "react";

import image1 from '../Images/Serv-03.png';
import image2 from '../Images/Scope.png';

function Services3() {
  return (
<div>
    <div className="about">
        <h2>Site Survey</h2>
        <p>Site surveying is a crucial step for any telecommunication project, as it involves the collection and analysis of data about the site and the network requirements. At our company, we have the expertise and experience to conduct site surveying services for a range of telecommunication applications.</p>
    </div>
    <div className="grid">
        <div className="aboutService">
            <h2>Surveying</h2>
            <p>Site surveying stands as an indispensable practice in the telecommunications sector, where detail is everything. This process entails the detailed measurement and mapping of a site’s physical attributes—ranging from pit locations to conduit routes and spatial dimensions.</p>
            <p>By keeping our surveying process simple, we ensure that our team can set up and maintain communication networks with fewer complications and greater success. The clarity and simplicity of site surveying are key to reducing errors and avoiding the need for repeat visits.</p>
        </div>

        <div className="centreImage"> <img src={image1} alt="Open Trench"/></div>

        <div className="centreImage"> <img src={image2} alt="Open Trench"/></div>
	
        <div className="aboutService">
            <h2>Scoping</h2>
            <p>Scoping is the process of defining and documenting the scope and specifications of the network project, such as the objectives, the deliverables, the budget, and the timeline. We use reliable tools and methods to perform scoping, such as:
            We use site survey checklists and questionnaires to collect all the information and data about the site and the network requirements, such as the cabling, the equipment, the electrical work, the wi-fi coverage, the data rates, the network capacity, and the quality of service.
            We use site survey reports and diagrams to present and communicate the findings and recommendations of the site survey, such as the number and placement of the network components, the cable routing and termination, the power and grounding requirements, and the testing and verification procedures.
            </p>
        </div>
    </div>
</div>
  );
}

export default Services3;
import React from "react";

import CarouselWidget from "../assets/generalCarousel";

import image1a from '../Images/PPInstall.jpg';
import image1b from '../Images/PPI2.jpg';
//import image1c from '../Images/Caro-01.png';
import image1d from '../Images/PPI1.jpg';
import image1e from '../Images/PPI4.jpg';

import image2a from '../Images/Serv-03.png'; 
import image2b from '../Images/newsite2.jpg'; 
import image2c from '../Images/newsite5.jpg';

function Services5() {

    const imageGroup = [
        [image1a,image1b,image1d,image1e],
        [image2a,image2b,image2c]
    ];

  return (
<div>
    <div className="about">
        <h2>New Developments and Sub-Divisions </h2>
        <p>We provide the best advice and knowledge to Developers and their Project team around all aspects of telecommunications pit and pipe design and construction. We are across many different carrier requirements to give the developers we work with good choice when choosing a telecommunications carrier to service their development.</p>
        <p>We construct pit and pipe networks that span across different locations and environments, such as urban, rural, and remote areas. We use advanced equipment and techniques, such as directional drilling and hydro excavation, to minimize the impact on the surrounding landscape and infrastructure.</p>
    </div>
    <div className="grid">
        <div className="aboutService">
            <h2>Pit & Pipe installation</h2>
            <p>Pit & Pipe installation is the unsung hero of this domain, providing a robust foundation for the intricate network of cables and wires that power our connections.</p>
            <p>They enable the rapid deployment of services, enhance network resilience, and are pivotal in future-proofing our infrastructure against the ever-increasing demands for data transmission.</p>
            <p>At the heart of our operations, we prioritize the integrity of the network. By adhering to stringent industry standards and specifications, we ensure that every pit and pipe installed serves as a bastion of safety and reliability. This commitment safeguards the seamless flow of data across various network nodes, fortifying the backbone of our digital society.</p>
        </div>

        <CarouselWidget images={imageGroup[0]}/>

        <CarouselWidget images={imageGroup[1]}/>

        <div className="aboutService">
            <h2>Pit & Pipe Pre-Provisioning</h2>
            <p>Our approach begins with meticulous planning, tailoring pit and pipe layouts to meet the anticipated demands and capacities of future networks. This foresight in infrastructure design not only streamlines the installation process but also minimizes disruptions during network enhancements.</p>
            <p>By investing in Pit & Pipe Pre-Provisioning, the telecommunications industry can enjoy the dual benefits of cost-effectiveness and time efficiency, all while laying the foundation for the technological advancements of the future.</p>
            <p>By anticipating the needs of tomorrow’s connectivity, we lay the groundwork for seamless expansions and upgrades, ensuring our clients are always ahead of the curve.</p>
        </div>
    </div>
</div>
  );
}

export default Services5;
import React from "react";

import CarouselWidget from "../assets/generalCarousel";

import image1a from '../Images/cleanRack.jpg';
import image1b from '../Images/ports3.png';

import image2a from '../Images/tower1.png';
import image2b from '../Images/tower2.png';
 
import image3a from '../Images/racksplice.jpg';
import image3b from '../Images/Lambramp01.png';
import image3c from '../Images/ports1.png';

function Services6() {

    const imageGroup = [
        [image1a,image1b],
        [image2a,image2b],
        [image3a,image3b,image3c]
    ];
  return (
<div>
    <div className="about">
        <h2>Cabling Private Networks</h2>
        <p>We provide specialist solutions for all your private data cabling requirements. We have high regard in all types of inter and intra building copper and fibre cabling. If you are wanting an data point installed at your home or fibre ran between buildings at work are able to look after your data cabling requirements.</p>
        <p>We offer advance testing of all cables to ensure the cables handed over to you are ready to use.</p>
    </div>
    <div className="grid">
        <div className="aboutService">
            <h2>Private Data Cabling</h2>
            <p>In today’s interconnected world, the backbone of communication lies within the hidden highways of data cabling. Whether it’s a cozy corner in your residence needing a single data point or an expansive corporate complex requiring a comprehensive network, our tailored data cabling solutions bridge the gap between you and the world.</p>
            <p>With a wealth of knowledge and a track record of success, our team is equipped to embark on data cabling ventures of any scale. From modest setups to intricate networks, we bring a blend of traditional craftsmanship and cutting-edge technology to the table, ensuring that your telecommunications infrastructure is second to none.</p>
        </div>

        <CarouselWidget images={imageGroup[0]}/>

        <CarouselWidget images={imageGroup[1]}/>

        <div className="aboutService">
            <h2>Inter & Intra Building Cabling</h2>
            <p>Our expertise in inter-building and intra-building cabling ensures that your devices and networks communicate efficiently, whether within a single facility or across multiple locations.</p>
            <p>Whether you’re linking multiple devices within a single building or integrating networks across diverse geographical sites, our cabling services bridge the gap. We ensure that your communication lines are as uninterrupted as your business operations, offering a seamless experience that powers your success in the telecommunications industry.</p>
        </div>

        <div className="aboutService">
            <h2>Data Point Installation</h2>
            <p>Our team is equipped to set up data points in a variety of environments, including your office, residence, or even the garage. We understand that each space has unique requirements, and we’re committed to providing customized installations that align with your specific needs.</p>
            <p>Our approach is to create robust and efficient networks that not only meet but exceed your expectations, ensuring that you’re well-equipped for both current demands and future advancements.</p>
            <p>Choose our services for data point installation and join the multitude of satisfied customers enjoying optimal connectivity. Contact us today to discuss your project and discover how we can assist in achieving your telecommunication objectives.</p>
        </div>
        
        <CarouselWidget images={imageGroup[2]}/>
    </div>
</div>
  );
}

export default Services6;
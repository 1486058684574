import React from "react";
import { NavLink } from "react-router-dom";

function NavHeader() {
    return(
      <div>
        <div className="navbar navbar-expand navbar-dark bg-dark">
          <NavLink to="./">Home</NavLink>
          {/* <NavLink to="./services">Our Services</NavLink> */}
          <NavLink to="./about">About Us</NavLink>
          <NavLink to="./contact">Contact Us</NavLink>
          {/* <NavLink to="./lequinn">Lequinn</NavLink> */}
        </div>
        <div className="banner"></div>
      </div>
    );
    
}

export default NavHeader;
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

function CarouselWidget({images}) {
  const [index, setIndex] = useState(0);

  function handleSelect(selectedIndex) {
    setIndex(selectedIndex);
  }
  
  return (
    <div>
      <Carousel className="centreImage" activeIndex={index} onSelect={handleSelect}>
        {/*Use the images array to map over the Carousel items*/}
        {images.map((image, i) => (
          <Carousel.Item key={i}>
            <img className = "sliderImg" src={image} alt={`Slide ${i + 1}`} text={`Slide ${i + 1}`}/>
          </Carousel.Item>
        ))}
      </Carousel>
  </div>
  );
}

export default CarouselWidget;
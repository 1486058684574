import React from "react";

import CarouselWidget from "../assets/generalCarousel";

import image1a from '../Images/LequinnLogo.png';

import image2a from '../Images/Sched01.PNG';
import image2b from '../Images/Sched02.PNG';

function About() {
  const imageGroup = [
    [image1a],
    [image2a,image2b] 
  ];
  return (
  <div>
    <div className="about">
        <h2>Lequinn</h2>
        <p>LeQuinn is a comprehensive project management tool designed specifically for subcontractors who need to oversee multiple projects with varying demands.
          It simplifies the entire project lifecycle, from quoting to project sign-off, with a user-friendly interface that adapts to different project types and workflows.
          LeQuinn’s scheduling tool integrates multiple teams into a single calendar, providing detailed task information, color-coded status updates, and powerful search capabilities for efficient planning and execution.
          Ideal for industries like telecommunications, LeQuinn is the key to enhanced efficiency and productivity in the demanding world of project management.</p>
    </div>
    <div className="grid">
      <div className="aboutService">
        <h2>What is Lequinn</h2>
        <p>Subcontractors often face the daunting task of managing a multitude of projects, each with its own set of complexities and requirements. This is where Lequinn steps in, offering a robust and intuitive solution that promises to streamline the entire project management process from start to finish.</p>
        <p>At the heart of Lequinn is the desire to serve as a one-stop shop for subcontractors. From the moment a project is quoted until the final payment is processed, Lequinn is there every step of the way. Its user-friendly interface allows subcontractors to set up different project types, each with a distinct flow of work. This adaptability ensures that Lequinn can handle a wide array of tasks, making it an ideal choice for companies that offer a diverse range of services.</p>
        <p>One of the most significant challenges in project management is maintaining a clear overview of all ongoing tasks and their respective statuses.Its thoughtful features and seamless integration into the subcontracting workflow make it an invaluable asset for any company looking to optimize their project management practices. Whether you’re in telecommunications or any other industry that relies on subcontracting, Lequinn is poised to elevate your business to new heights of efficiency and productivity.</p>
      </div>

      <centreImage>
        <img className = "logo" src={image1a} alt="logo" width = "100%" text="logo"/>
      </centreImage>
      {/*<CarouselWidget images={imageGroup[0]}/>*/}

      <CarouselWidget images={imageGroup[1]}/>

      <div className="aboutService">
        <h2>Scheduling Tool</h2>
        <p>In the fast-paced world of project management, staying organized isn’t just a benefit, it’s a necessity. With multiple teams juggling numerous tasks, the key to success lies in a tool that offers both visibility and coordination.</p>
        <p>Our scheduling tool allows for seamless integration of multiple groups within a single calendar view. This feature not only enhances collaboration but also fosters transparency across all projects, ensuring that every team member is on the same page.</p>
        <p>Each task is meticulously detailed, displaying the scope of work, project assignments, and other essential information. This granular approach ensures that no aspect of a project is overlooked, providing a comprehensive overview at a glance.</p>
        <p>To streamline workflow and prioritize tasks, our tool automatically assigns color codes based on task status. This visual cue instantly communicates progress and urgency, making it easier to manage deadlines and milestones.</p>
        <p>With our powerful search capabilities, you can filter tasks by various criteria, simplifying the process of locating specific items amidst complex project landscapes. This feature is invaluable for quick navigation and task retrieval.</p>
        <p>By leveraging the robust features of our scheduling tool, project planning, tracking, and execution become more efficient. The result is a smoother project lifecycle and better outcomes.</p>
      </div>
    </div>
  </div>
   
  );
}

export default About;
import React from "react";

import CarouselWidget from "../assets/generalCarousel";

import image00a from '../Images/Survey03.jpg'; 
import image00b from '../Images/sitesurvey.jpg'; 
import image00c from '../Images/Site04.jpg'; 

import image1a from '../Images/Splice2.jpg';
import image1b from '../Images/RackSetup.png';
import image1c from '../Images/BJL.jpg';
import image1d from '../Images/FJL02.png';

import image2a from '../Images/Copper.png';

import image3a from '../Images/Internal.jpg';
import image3b from '../Images/InternalRoof.png';
import image3c from '../Images/conduit.jpg';

import image4a from '../Images/Haul.jpg';
import image4b from '../Images/haul3.jpg';
import image4c from '../Images/haul2.jpg';
import image4d from '../Images/Scope.png';

function Services4() {

    const imageGroup = [
        [image00a,image00b,image00c],
        [image1a,image1b,image1c,image1d],
        [image2a],
        [image3a,image3b,image3c],
        [image4a,image4b,image4c,image4d]
    ];

  return (
<div>
    <div className="about">
        <h2>Telecommunications</h2>
        <p>We provide end-to-end design and construction solutions for telecommunications infrastructure and equipment, including the NBN and Telstra networks and have worked with many other carrier networks.</p>
        <p>We offer all aspects of:
        <li>Formulation of preliminary designs</li>
        <li>Internal and external site survey, extimating and reporting</li>
        {/*<li>Rod Roping and Proving of all networks</li>*/}
        <li>Civil construction of pit and pipe networks </li>
        <li>Small and large capacity cable hauling </li>
        <li>All types of copper cable jointing/testing </li>
        <li>Optic fibre splicing and testing</li>
        <li>Compiling and submission of all artifacts to Asbuilt standard required by carriers </li></p>

    </div>
    <div className="grid">

    <CarouselWidget images={imageGroup[0]}/>

    <div className="aboutService">
        <h2>Surveying</h2>
        <p>Site surveying stands as an indispensable practice in the telecommunications sector, where detail is everything. This process entails the detailed measurement and mapping of a site’s physical attributes, ranging from pit locations to conduit routes and spatial dimensions.</p>
            <p>By keeping our surveying process simple, we ensure that our team can set up and maintain communication networks with fewer complications and greater success. The clarity and simplicity of site surveying are key to reducing errors and avoiding the need for repeat visits.</p>
    </div>

    <div className="aboutService">
            <h2>Optic Fibre</h2>
            <p>Optic fibre technology stands at the forefront of modern telecommunications, offering unparalleled data and voice transmission capabilities.</p>
            <p>By utilizing specialized equipment such as winches, ropes, and rollers, we ensure the cables are laid through subterranean ducts with precision. This approach safeguards the cables from undue stress, preserving their integrity and functionality.</p>
            <p>In the realm of telecommunications, FTTC and FTTP stand as significant advancements. FTTC brings fibre optic cables directly to the kerbside, minimizing reliance on existing copper lines and enhancing connection speeds. Conversely, FTTP involves running fibre optic lines straight to the premises, offering the zenith of speed and reliability in internet connectivity.</p>
            <p>Joining optic fibre cables is an art that demands precision. Whether through fusion splicing or mechanical splicing, we meticulously align and fuse the fibre ends. This process ensures a connection with minimal signal loss and maximum reliability. To validate the quality of these connections, we employ tools like optical power meters and optical time-domain reflectometers.</p>
        </div>

        <CarouselWidget images={imageGroup[1]}/>

        <CarouselWidget images={imageGroup[2]}/>
	
        <div className="aboutService">
            <h2>Copper</h2>
            <p>Copper wiring has long been the standard in telecommunications, prized for its reliability and cost-effectiveness. Utilizing electrical signals, copper cables transmit data and voice communications through twisted wire pairs, seamlessly integrating with existing network infrastructures.</p>
            <p>While copper wiring has served us well, the telecommunications industry is evolving. The shift towards Fiber to the Premise (FTTP) is underway, promising faster speeds and greater bandwidth. This transition represents a significant leap forward, as FTTP utilizes optical fibers to deliver enhanced connectivity right to the edge of consumers’ properties.</p>
            <p>As we embrace this new era, the value of copper remains undeniable. It continues to provide a reliable foundation for telecommunication networks worldwide, even as we pave the way for the advanced capabilities of FTTP.</p>
        </div>
            
        <div className="aboutService">
            <h2>Internal Cable Installations</h2>
            <p>Our expertise extends to selecting the optimal cable types and sizes for each unique setting. Each choice is tailored to meet the demands of the telecommunication industry’s evolving landscape. We ensure these vital connectors are routed and secured with precision</p>
            <p>Post-installation, our commitment to excellence continues. We conduct thorough testing and termination procedures to guarantee performance and reliability. Each cable and wire is then labeled and documented, creating a clear roadmap for future maintenance and upgrades.</p>
        </div>

        <CarouselWidget images={imageGroup[3]}/>

        <CarouselWidget images={imageGroup[4]}/>

        <div className="aboutService">
            <h2>Rod Rope Proving</h2>
            <p>Our technicians employ rodders and duct rods, designed for resilience and flexibility, to navigate the intricate pathways of ducts and conduits. These tools are adept at maneuvering through tight bends and twists, maintaining their integrity without succumbing to breakage or kinks.</p>
            <p>To guarantee an obstruction-free environment, we utilize various sizes and shapes of duct brushes and mandrels. These attachments are meticulously pulled through the ducts and conduits, removing any accumulated dirt, debris, or blockages, thus paving the way for unimpeded cable installation.
            The rodders themselves have a trace wire running through the middle of them. THis makes it possible to locate the existing network for depths underground.</p>
            <p>The final step involves the use of draw ropes and winches, which are attached and drawn through the ducts and conduits. Our high-strength, friction-resistant ropes are engineered to handle the substantial tension and wear that come with cable hauling and jointing operations.</p>
        </div>
    </div>
</div>
  );
}

export default Services4;
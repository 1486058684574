import React from "react";

import CarouselWidget from "../assets/generalCarousel";

import image1a from '../Images/serviceLocation.jpg';

import image2a from '../Images/markups3.png';
import image2b from '../Images/markups05.jpg';
import image2c from '../Images/markups2.jpg';
import image2d from '../Images/blockage.png';

function Services2() {
  const imageGroup = [
    [image1a],
    [image2a, image2b,image2c,image2d] 
];
  return (
<div>
    <div className="about">
        <h2>Underground Service Locations</h2>
        <p>We locate and identify the existing underground services, such as water, gas, electricity, and sewerage.</p>
        <p>This is a great service we offer to help you plan for any works that you may want to do in the future.</p>
    </div>
    <div className="grid">
      <CarouselWidget images={imageGroup[0]}/>

      <div className="aboutService">
        <h2>Underground Detetion</h2>
        <p>Before the first shovel breaks ground on any civil construction project, a crucial step must be taken to safeguard the intricate web of utilities that lie beneath our feet. Sophisticated detection technologies are employed to identify these services. This preemptive measure is a vital practice that upholds the integrity of essential services and mitigates the risk of costly disruptions.</p>
        <p>In the realm of telecommunications, the stakes are particularly high. The industry relies on an extensive network of buried cables and conduits, which, if compromised, can disrupt communication services critical to both individuals and businesses.</p>
        <p>By integrating underground service location into the initial stages of construction planning, we not only protect the existing infrastructure but also pave the way for seamless enhancements to our telecommunication networks.</p>
      </div>

      <div className="aboutService">
        <h2>Site Markups</h2>
        <p>In the intricate web of underground utilities, on-site markings emerge as the unsung heroes, ensuring the seamless operation of our telecommunication services. These vibrant symbols, painted by skilled service locators, are not mere decorations but critical indicators of the hidden network below.</p>
        <p>Though the bright hues of spray paint may fade with time or wash away with the rain, their significance does not. These temporary marks are the blueprint for safety, guiding excavators away from the vital arteries of our urban landscape. They are the difference between a day’s work and a costly service outage.</p>
        <p>For the telecommunications industry, these markings are not just a regulatory compliance; they are a safeguard against service disruptions. They are the first step in the delicate dance of development, ensuring that as we reach towards the sky with our structures, we do not sever the connections that bind us.</p>
      </div>

      <CarouselWidget images={imageGroup[1]}/>
    </div>
</div>
  );
}

export default Services2;
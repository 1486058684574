import React, { useRef } from "react";
import { Form, InputGroup, Button } from 'react-bootstrap';
import { FaUserCircle, FaEnvelope, FaCommentDots } from 'react-icons/fa';
import { toast } from 'react-toastify';
import image1 from '../Images/officeCrop.jpg';

function Contact() {

  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });

    // Create the form submission request manually
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(data).toString()
    })
    .then(() => {
      toast.success('Message sent successfully');
      formRef.current.reset();
    })
    .catch((error) => {
      toast.error('An error occurred, please try again');
      console.error(error);
    });
  };

  return (
    <div>
      <div class="about">
        <h2>Contact Us</h2>
      </div>
      <div class="contactGrid">
        <div class="contactGrid-item">
          <h2>Get in touch with us</h2>
          <p>We are always happy to hear from you. Whether you have a question, a suggestion, or a complaint, we will do our best to assist you. You can contact us by phone, email, or using the form on this page. </p>
          <div class="image-container">
            <img src={image1} alt="test"/>
          </div>
        </div>
        <div class="contactGrid-item">
          <h2>Contact form</h2>
          <p>We are always happy to hear from you. Whether you have a question, a suggestion, or a complaint, we will do our best to assist you. You can contact us by phone, email, or using the form on this page. </p>
          
          <div className="App">

            <Form ref={formRef} name="contact" method="POST" data-netlify="true" onSubmit={handleSubmit}>
              <input type="hidden" name="form-name" value="contact" />

              <Form.Group controlId="formName" className="py-2">
                <Form.Label>Name</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaUserCircle />
                  </InputGroup.Text>
                  <Form.Control 
                    type="text"
                    name="name"
                    placeholder="Name…"
                    required
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formEmail" className="py-2">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaEnvelope />
                  </InputGroup.Text>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email…"
                    required
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formMessage" className="py-2">
                <Form.Label>Message</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FaCommentDots />
                  </InputGroup.Text>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    placeholder="Message…"
                    required
                  />
                </InputGroup>
              </Form.Group>

              <div className="py-2">
                <Button variant="primary" type="submit">
                  Send
                </Button>
              </div>

            </Form>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Contact;
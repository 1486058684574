import React from "react";

import CarouselWidget from "../assets/generalCarousel";

import image1a from '../Images/Crew.jpg';

import image2a from '../Images/safety.jpg';
import image2b from '../Images/Safety02.jpg';

function About() {
  const imageGroup = [
    [image1a],
    [image2a,image2b] 
  ];
  return (
  <div>
    <div className="about">
        <h2>About Us</h2>
    </div>
    <div className="grid">
      <div className="aboutService">
        <h2>Who We Are</h2>
        <p>Adtech Contracting Pty Ltd is a leading telecommunications contracting company based in Bendigo, Victoria.<br/>
        We strive to create a positive safety culture within our company, where everyone is aware of their roles and responsibilities, and where safety is always the top priority.<br/>
        United in our mission, we stand firm in the belief that collaboration is the key to maintaining an impeccable safety record. Our services encompass the full spectrum of telecommunications infrastructure and equipment, delivering comprehensive solutions tailored to your unique requirements.<br/>
        Boasting a rich history spanning 17 years and a dynamic team of over 20 professionals, Adtech Contracting is an owner-operated beacon of expertise, poised to manage and execute projects of any scale with precision and finesse.<br/>
        Your go-to ally for all things telecom, Adtech Contracting pledges to flexibly scale our workforce to match the demands of your project, big or small, ensuring unparalleled service and results that speak for themselves.</p>
      </div>

      <centreImage>
        <img className = "logo" src={image1a} alt="logo" width = "100%" text="logo"/>
      </centreImage>
      {/*<CarouselWidget images={imageGroup[0]}/>*/}

      <CarouselWidget images={imageGroup[1]}/>

      <div className="aboutService">
        <h2>Accreditation </h2>
        <p>Our work crews are trained and supervised to excel in cable hauling and civil excavation. Our seasoned crew leaders guide our employees, ensuring expertise in managing both copper and fibre networks across regional Victoria, including the expansive Greater Melbourne & Geelong areas.</p>
        <p>They are proficient in working with both copper and fibre networks throughout regional Victoria, southern NSW, and the Greater Melbourne & Metro areas.</p>
        <p>Beyond our SWMS, we uphold a comprehensive safety protocol that includes consistent training, vigilant supervision, thorough audits, detailed inspections, and a robust incident reporting system. This multifaceted approach ensures that safety remains at the core of our operations, reflecting our dedication to maintaining the highest standards of accreditation.</p>
      </div>
    </div>
  </div>
   
  );
}

export default About;
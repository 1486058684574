import React from "react";

import image1 from '../Images/Serv-01.png';
import image2 from '../Images/Serv-02.png';
import image3 from '../Images/Serv-03.png';
import image4 from '../Images/Serv-04.jpg';

import { NavLink } from "react-router-dom";


function Services() {
  return (
    <div>
      <div class="about">
        <h2>Services</h2>
        <p>Below are the services we provide, we are a growing company and are adding more value to our customers every day. Click below for more information.</p>
      </div>

      <div class="grid">
        <NavLink to="./construction" class="grid-item">
          <div class="grid-container"> <img src={image1} alt="test" style={{width: '100%'}}/> <p class="titlez">Civil Construction</p></div>
        </NavLink>
        <NavLink to="./hauling" class="grid-item">
          <div class="grid-container"> <img src={image2} alt="test" style={{width: '100%'}}/> <p class="titlez">Cable Hauling & Jointing</p></div>
        </NavLink>
        <NavLink to="./surveying" class="grid-item">
          <div class="grid-container"> <img src={image3} alt="test" style={{width: '100%'}}/> <p class="titlez">Site Survey</p></div>
        </NavLink>
        <NavLink to="./resources" class="grid-item">
          <div class="grid-container"> <img src={image4} alt="test" style={{width: '100%'}}/> <p class="titlez">Our Resources</p></div>
        </NavLink>
      </div>
    </div>
  );
}

export default Services;
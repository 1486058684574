import React from "react";

import image0 from '../Images/DRILL.jpeg';

import image1 from '../Images/Serv-01.png';
import image2 from '../Images/Scope.png';
import image3 from '../Images/cleanRack.jpg';
import image4 from '../Images/Serv-03.png';
import image5 from '../Images/Internal.jpg';

import { NavLink } from "react-router-dom";
function Home() {
  return (
    <div>
      <div class="about">
      <p>Adtech Contracting Pty Ltd is a leading telecommunications contracting company based out of Bendigo, Victoria.
      We provide end-to-end solutions for telecommunications infrastructure and equipment, including the NBN network, developer pit and pipe work, and carrier licensing.<br/>
      We serve clients across Victoria and parts of Southern New South Wales, delivering high-quality products and standards that set us apart from the rest.
      With 17 years of experience and a team of over 20 staff members, we are an owner-operated company that can handle any project from start to finish.<br/>
      Adtech Contracting is your trusted partner for all your telecommunications needs. </p>
      </div>

      <div class="grid">
        <NavLink to="./construction" class="grid-item">
          <div class="grid-container"> <img src={image1} alt="test" style={{width: '100%'}}/> <p class="titlez">Civil Construction</p></div>
        </NavLink>
        <NavLink to="./locating" class="grid-item">
          <div class="grid-container"> <img src={image2} alt="test" style={{width: '100%'}}/> <p class="titlez">Underground Service Locations</p></div>
        </NavLink>
        <NavLink to="./telecommunication" class="grid-item">
          <div class="grid-container"> <img src={image3} alt="test" style={{width: '100%'}}/> <p class="titlez">Telecommunications</p></div>
        </NavLink>
        <NavLink to="./developments" class="grid-item">
          <div class="grid-container"> <img src={image4} alt="test" style={{width: '100%'}}/> <p class="titlez">New Developments and Sub-Divisions</p></div>
        </NavLink>
        <NavLink to="./cabling" class="grid-item">
          <div class="grid-container"> <img src={image5} alt="test" style={{width: '100%'}}/> <p class="titlez">Cabling Private Networks</p></div>
        </NavLink>
      </div>
    </div>
  );
}

export default Home;